import fetchCategories from './fetchCategories';

export const getCategories = async () => {
  const categoriesData = await fetchCategories();
  return categoriesData;
};

export const getCategoryOptions = async (categoryId) => {
  const categoriesData = await fetchCategories();
  const category = categoriesData.find((cat) => cat.id === categoryId);
  return category ? category.options : [];
};
