import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import HomePage from './components/HomePage';
import CategoryPage from './components/CategoryPage';
import Header from './components/Header';
import './App.css';

const App = () => {
  const basename = process.env.NODE_ENV === 'production' ? '/DigitalSamplesShowcase/showcase/' : '/';
  return (
    <Router basename={basename}>
      <Header />
      <Route
        render={({ location }) => (
            <CSSTransition key={location.key} classNames="page-transition" timeout={300}>
              <Switch location={location}>
                <Route exact path="/" component={HomePage} />
                <Route path="/category/:categoryId" component={CategoryPage} />
              </Switch>
            </CSSTransition>
        )}
      />
    </Router>
  );
};

export default App;