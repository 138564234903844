const fetchCategories = async () => {
    try {
        const response = await fetch(`${process.env.PUBLIC_URL}/categories.json`);
      if (!response.ok) {
        throw new Error('Failed to fetch categories.json');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching categories:', error);
      return []; // Return an empty array or handle the error as per your requirement
    }
  };
  
  export default fetchCategories;
  