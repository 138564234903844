import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Divide as Hamburger } from "hamburger-react";
import { getCategories, getCategoryOptions } from "../CategoryData";
import ImageModal from './ImageModal';
import VideoModal from './VideoModal';
import styles from "./CategoryPage.module.css";

const CategoryPage = () => {
  const { categoryId } = useParams();
  // const categories = getCategories();
  // const categoryOptions = getCategoryOptions(categoryId);
  const [isOpen, setOpen] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const menuRef = useRef(null);
  const history = useHistory();
  const [initialLoad, setInitialLoad] = useState(true);
  const [isContactsPage, setContactsPage] = useState(false);

  const [categories, setCategoriesData] = useState([]);
  const [categoryOptions, setSelectedCategoryOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedImageTitle, setImageTitle] = useState('');
  


  const [showVideoModalOpen, setVideoModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');

  const openModal = (imageUrl, imageTitle) => {
    setSelectedImage(imageUrl);
    setImageTitle(imageTitle);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedImage('');
    setImageTitle('');
    setIsModalOpen(false);
  };


  // Function to open the video popup
  const openVideoPopup = (videoSrc) => {
    console.log("show video::: ",videoSrc)
    setSelectedVideo(videoSrc);
    setVideoModalOpen(true);
  };

  // Function to close the video popup
  const closeVideoPopup = () => {
    setSelectedVideo('');
    setVideoModalOpen(false);
  };


  

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCategories();
      setCategoriesData(data);

      // Fetch category options based on the selected categoryId
      const options = await getCategoryOptions(categoryId);
      setSelectedCategoryOptions(options);
    };
    fetchData();

    setFadeIn(true);
    if (menuRef.current) {
      if (initialLoad) {
        setInitialLoad(false);
        menuRef.current.classList.add(styles.menuVisible);
      }
    }
  }, [categoryId, initialLoad]);

  useEffect(() => {
    if (categoryOptions.length) {
      if (categoryOptions[0].title === "contact_info") {
        setContactsPage(true);
      } else {
        setContactsPage(false);
      }
    }
  }, [categoryOptions]);

  // Handle loading or error states, if required
  if (!categories.length || !categoryOptions.length) {
    return <div>Loading...</div>;
  }

  const handleMenuItemClick = (category) => {
    if (category.id !== categoryId) {
      menuRef.current.classList.add(styles.menuVisible);
      history.push(`/category/${category.id}`);
    }
  };
  const handleCategoryClick = () => {
    // menuRef.current.classList.add(styles.menuVisible);
    history.push(`/category/contact`);
  };
  const handleIconClick = (url, title, open, type) => {
    console.log(open, type);

    if (open) {
      if (open === "inline") {
        let open_link_type = "image";
        if (type) {
          if (type === "image") {
            open_link_type = "image";
          }
          if (type === "video") {
            open_link_type = "video";
          }
        }

        switch (open_link_type) {
          case "image":
            openModal(url, title)
            break;
          case "video":
            openVideoPopup(url, title)
            break;
          default:
            break;
        }
      }
    } else {
      window.open(url, "_blank");
    }
  };

  return (
    <div className={styles.categoryPage}>
      <div className={[styles.menu_bar]}>
        <div className={[styles.category_title]}>
          {categories.map((category) =>
            category.id === categoryId ? category.title : ""
          )}
        </div>
        <div className={[styles.category_menu_icon]}>
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>
      </div>
      {/* <div className={`${styles.menu} ${isOpen ? styles.selected : ''`} ref={menuRef}> */}
      <div
        className={`${styles.menu} ${isOpen ? styles.showMenu : ""}`}
        ref={menuRef}
      >
        {categories.map((category) =>
          category.id !== "contact" ? (
            <div
              key={category.id}
              className={`${styles.menuItem} ${
                category.id === categoryId ? styles.selected : ""
              }`}
              onClick={() => handleMenuItemClick(category)}
            >
              {category.title}
            </div>
          ) : (
            ""
          )
        )}
        <div className={styles.contact_container}>
          <div
            className={`${styles.contact} ${
              categoryId === "contact" ? styles.selected : ""
            }`}
            onClick={() => handleCategoryClick()}
          >
            Contact Information
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div
          className={`${styles.optionList} ${
            isContactsPage ? styles.contacts_page : ""
          }`}
        >
          {categoryOptions.map((option, index) =>
            option.title !== "contact_info" ? (
              <div
                key={"opt_"+option.id}
                className={`${styles.optionCard} ${
                  fadeIn ? styles.fadeIn : ""
                }`}
                style={{ animationDelay: `${(index + 1) * 0.1}s` }}
                onClick={() =>
                  handleIconClick(option.url, option.title, option.open, option.type)
                }
              >
                <div
                  className={styles.icon_image}
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + option.icon
                    })`,
                  }}
                  aria-label={option.title}
                ></div>
                <div className={styles.icon_label_container}>
                  <h2 className={styles.label}>{option.title}</h2>
                </div>
              </div>
            ) : (
              <div key={"opt_"+option.id}>
                <div className={styles.contact_text_container}>
                  <div
                    className={styles.contact_sec + " " + styles.contact_title}
                  >
                    <span className={styles.vp_name}>Raman Arora</span>
                    <span>Vice President, Ed-Tech & Digital Learning</span>
                  </div>
                  <div
                    className={styles.contact_sec + " " + styles.contact_tel}
                  >
                    <div
                      className={styles.contact_icon}
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL +
                          "/images/icons/phone_icon.svg"
                        })`,
                      }}
                      title="Telephone"
                    ></div>
                    <a href="tel:+1703342944" className={styles.txt_link}>
                      +1.703.342.944
                    </a>
                  </div>
                  <div
                    className={styles.contact_sec + " " + styles.contact_email}
                  >
                    <div
                      className={styles.contact_icon}
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL +
                          "/images/icons/email_icon.svg"
                        })`,
                      }}
                      title="Email"
                    ></div>
                    <a
                      href="mailto:raman.arora@aptaracorp.com?subject=Hey!"
                      className={styles.txt_link}
                    >
                      Raman.Arora@aptaracorp.com
                    </a>
                  </div>
                  <div
                    className={styles.contact_sec + " " + styles.contact_loc}
                  >
                    <div
                      className={styles.contact_icon}
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL +
                          "/images/icons/location_icon.svg"
                        })`,
                      }}
                      title="Location"
                    ></div>
                    4501 Daly Drive, Suite 312,
                    <br />
                    Chantilly VA 20151
                  </div>
                  <div
                    className={styles.contact_sec + " " + styles.contact_url}
                  >
                    <div
                      className={styles.contact_icon}
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL + "/images/icons/url_icon.svg"
                        })`,
                      }}
                      title="Website"
                    ></div>
                    <a
                      href="https://www.aptaracorp.com/why-aptara/our-clients/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.txt_link}
                    >
                      Our Clients
                    </a>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {isModalOpen && (
        <ImageModal imageUrl={selectedImage} imageTitle={selectedImageTitle} onClose={closeModal} />
      )}

      {showVideoModalOpen && (
        <VideoModal videoSrc={selectedVideo} onClose={closeVideoPopup} />
      )}

    </div>
  );
};

export default CategoryPage;
